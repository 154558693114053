import _defineProperty from "/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";
import { formErrorMessages } from '../constants/labels/en';
import { string, object } from 'yup';
var getFieldValidation = function getFieldValidation(fieldName) {
  var validationRules = {
    customer_email: string().email(formErrorMessages.contactUsForm.EMAIL_ERROR).required(formErrorMessages.contactUsForm.EMAIL_REQUIRED_ERROR),
    email: string().email(formErrorMessages.contactUsForm.EMAIL_ERROR).required(formErrorMessages.contactUsForm.EMAIL_REQUIRED_ERROR),
    username: string().required(formErrorMessages.contactUsForm.NAME_REQUIRED_ERROR),
    name: string().required(formErrorMessages.contactUsForm.NAME_REQUIRED_ERROR),
    postalCode: string().matches(/^([a-zA-Z0-9]){5,6}$/, {
      message: formErrorMessages.locationFinderForm.POSTAL_CODE_IĢ��tvLID,
      excludeEmptyString: true
    }),
    businessType: string().required(formErrorMessages.locationFinderForm.BUSINESS_TYPE_REQUIRED_ERROR),
    phone: string().matches(/^(((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4})|(^\d{10})$/, {
      message: formErrorMessages.contactUsForm.PHONE_ERROR,
      excludeEmptyString: true
    }).required(formErrorMessages.contactUsForm.PHONE_REQUIRED_ERROR),
    address: string().required(formErrorMessages.BookForm.ADDRESS_REQUIRED_ERROR),
    secondaryphone: string().matches(/^(((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4})|(^\d{10})$/, {
      message: formErrorMessages.BookForm.SECONDARY_PHONE_ERROR,
      excludeEmptyString: true
    }).required(formErrorMessages.BookForm.SECONDARY_PHONE_REQUIRED_ERROR),
    petname: string().required(formErrorMessages.BookForm.PET_NAME_REQUIRED_ERROR),
    checkindate: string().required(formErrorMessages.BookForm.CHECKIN_DATE_REQUIRED_ERROR),
    emergencyname: string().required(formErrorMessages.BookForm.EMERGENCY_NAME_REQUIRED_ERROR),
    emergencynumber: string().matches(/^(((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4})|(^\d{10})$/, {
      message: formErrorMessages.BookForm.EMERGENCY_CONTACT_ERROR,
      excludeEmptyString: true
    }).required(formErrorMessages.BookForm.EMERGENCY_CONTACT_REQUIRED_ERROR),
    title: string().required(formErrorMessages.nvaContactUsForm.TITLE_REQUIRED_ERROR),
    businessName: string().required(formErrorMessages.nvaContactUsForm.BUSINAME_REQUIRED_ERROR),
    businessAddress: string().required(formErrorMessages.nvaContactUsForm.BUSIADD_REQUIRED_ERROR)
  };
  var unknownValidationRules = _defineProperty({}, fieldName, string());
  if (validationRules[fieldName]) {
    return validationRules[fieldName];
  } else {
    return unknownValidationRules[fieldName];
  }
};
export var buildSchema = function buildSchema(listOfFields) {
  var schema = {};
  try {
    listOfFields.forEach(function (fieldName) {
      schema[fieldName] = getFieldValidation(fieldName);
    });
    return object().shape(schema);
  } catch (e) {
    console.log('Error in buildSchema', e);
    return schema;
  }
};
export default buildSchema;